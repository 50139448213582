.navbar-toggler-icon {
    width: 1.8em !important;
    height: 2em !important;
}

.welcome {
    .jumbotron {
        color: white;
        /*background-image: url("/images/tiendaloto-hero.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;*/
        background-color: azure;
        min-height: 50vh;
        padding-top: 75px;
        margin-bottom: 0;
        h1 {
            color: black;
            font-size: 46px;
        }
        p {
            color: black;
            font-size: 24px;
        }
    }
    .part {
        margin-top: 6em;
    }
    .main-content {
        min-height: 100vh;
        background-color: $white;
    }
    @media (max-width: 991px) {
        .main-content {
            padding-top: 70px;
            padding-bottom: 100px;
        }
    }
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.pricing {
    .card {
        border: none;
        //border-radius: 1rem;
        //transition: all 0.2s;
        //box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 30%);
        //padding: 0 13px 10px 13px;
        height: 100%;
        background-color: transparent;
    }
    .row-flex {
        display: flex;
        flex-wrap: wrap;
    }
    .card-img {
        height: 182px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        border-radius: 0;
        margin-bottom: 1em;
    }
    .card-title {
        margin: 0.5rem 0;
        font-size: 0.9rem;
        letter-spacing: .1rem;
        font-weight: bold;
        color: $white
    }
    .card-price {
        font-size: 1.8rem;
        margin: 0;
        color: #0000008a
    }
    .card-price .period {
        font-size: 0.8rem;
    }
    .fa-ul {
        padding: 0.5em;
        margin-left: 1.2em;
        //background-color: white;
    }
    ul li {
        margin-bottom: 1rem;
        font-size: 13px;
    }
    .text-muted {
        opacity: 0.7;
    }
    .btn {
        font-size: 80%;
        border-radius: 5rem;
        letter-spacing: .1rem;
        font-weight: bold;
        padding: 1rem;
        opacity: 0.7;
        transition: all 0.2s;
    }
    @media( max-width:1024px) {
        .card-img {
            height: 210px;
        }
        .card-price {
            font-size: 2.5rem;
        }
    }
    @media (max-width: 662px) {
        .card-img {
            height: 152px;
            background-size: 100% 100%;
        }
    }
}

.advise {
    h3 {
        margin: 20px 0;
    }
    p {
        margin-bottom: 10px;
    }
    ul {
        margin-left: 29px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    table,
    td,
    th {
        border-color: rgba(51, 51, 51, 0.12);
    }
    td,
    th {
        border-top: 1px solid rgba(51, 51, 51, 0.12);
        padding: 5px 10px 5px 5px;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #333333;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
}

.h5 {
    font-size: 15px;
}

.red-border {
    border: 4px solid #ff2828eb !important;
}

.green-border {
    border: 4px solid #3ce707 !important;
}

.yellow-border {
    border: 5px solid #d8e707 !important;
}

.admon-dashboard {
    .btn-block {
        height: 38px;
        font-size: 15px;
    }
    .last-messages {
        max-height: 240px;
        overflow-y: auto;
    }
    .statistic__item .icon i.green {
        color: rgb(36 145 3) !important;
        font-size: 160px !important;
    }
    .statistic__item .icon i.red {
        color: rgb(167 0 0 / 74%) !important;
        font-size: 160px !important;
    }
    .statistic__item .icon i.yellow {
        color: #d7c204 !important;
        font-size: 160px !important;
    }
    .recent-report2 {
        padding: 40px 20px 0 !important;
    }
    .title-3 {
        font-size: 18px !important;
        margin-bottom: 8px;
    }
    .recent-report2 {
        min-height: 240px!important;
        border-radius: 10px;
    }
}

.admin-dashboard {
    .percent-chart {
        padding: 0;
    }
    .dot--orange {
        background: #f1903d;
    }
    .dot--yellow {
        background: #ffed4a;
    }
}

.admin-dashboard.digital-numbers-buttons .icons .icon {
    cursor: pointer;
}

.digital-numbers-buttons .icons .icon {
    width: 12%;
    display: inline-block;
    text-align: center;
    color: rgb(99, 107, 111);
    .text {
        height: 55px;
        .premio {
            font-size: 18px;
            color: rgb(221, 0, 0);
            font-weight: 700;
        }
    }
}

.admin-message-modal {
    overflow-y: scroll;
    max-height: 500px;
}

.task-progress {
    padding-bottom: 4% !important;
}

.modal-transactions {
    max-width: 80vw;
}

@media (max-width: 991px) {
    .modal-transactions {
        max-width: none;
    }
}

.h2-hover-number:hover {
    background-color: rgba(0, 0, 0, .075);
}

.legend-custom {
    font-style: italic;
    font-size: smaller;
    text-indent: 0;
}

.statistics{
    [aria-expanded="true"] i:before{
        content: "\f146";
    }
    
    [aria-expanded="false"] i:before{
        content: "\f0fe";
    }
}
@media (max-width: 991px) {
    #detail-number-modal-content{
        max-width: 80vw;
    }
}

#custom_datatable{
    .position_custom_datable{
        position: absolute;
        z-index: 2;
    }
    @media (max-width: 765px) {
        .position_custom_datable{
            position: relative !important;
        }
        .dataTables_paginate{
            
            .pagination{
                width: 120vw;
            }
        }
    }
}

