.tab-content>.active {
    /* display: block; */
    display: contents;
}
.hidden{
    display: none!important;
}

.statistic__item {
    cursor:pointer;
    min-height: 240px !important;
    border-radius: 10px;
}

#loteries-table{
    .table td, .table th {
        padding: .75em .6rem;
    }
}

