.abono-card {
    .custom_input {
        outline: 0;
        border-width: 0 0 2px;
        width: 15px;
        -moz-appearance: textfield;
        margin-left: 10px;
    }
    .custom_input::-webkit-outer-spin-button,
    .custom_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .sub-text,
    .sub-text:hover {
        font-size: 10px;
        color: #636b6f !important;
    }
    .input-n-number {
        font-size: 25px;
        text-align: center;
    }
    .custom-switch .custom-control-input:checked~.custom-control-label::after {
        background-color: white !important;
        cursor: pointer !important;
    }
    .custom-switch .custom-control-input:checked~.custom-control-label::before {
        cursor: pointer !important;
    }
}

.img-abono {
    height: 3rem;
    width: auto;
    background-color: white;
}

.img-abono-table {
    height: 2rem;
    width: auto;
}

.subscription-active {
    background-color: rgb(152 170 96) !important;
}

.subscription-balance {
    background-color: rgb(238 13 16 / 81%) !important;
}